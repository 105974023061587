// ================================================================================================
//  File Name: app-invoice.scss
//  Description: Invoice Layout page layouts SCSS.
//  ----------------------------------------------------------------------------------------------
//  Item Name: Vuexy HTML Admin Template
//  Version: 1.0
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

@import '../bootstrap-extended/include'; // Bootstrap includes
@import '../components/include'; // Components includes

$product-details-bg: #fcfcfc;
$invoice-spacing: 1.45rem 0;
$invoice-card-body-padding: 2.5rem;

// Invoice Preview, Edit & Add
.invoice-preview,
.invoice-edit,
.invoice-add {
  .invoice-padding {
    padding-left: $invoice-card-body-padding;
    padding-right: $invoice-card-body-padding;
  }
  .table {
    th:first-child,
    td:first-child {
      padding-left: 2.5rem;
    }
  }
  .logo-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1.9rem;
    .invoice-logo {
      font-size: 2.142rem;
      font-weight: bold;
      letter-spacing: -0.54px;
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
  .invoice-title {
    font-size: 1.285rem;
    margin-bottom: 1rem;
    .invoice-number {
      font-weight: 600;
    }
  }
  .invoice-date-wrapper {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }

    .invoice-date-title {
      width: 7rem;
      margin-bottom: 0;
    }
    .invoice-date {
      margin-left: 0.5rem;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  .invoice-spacing {
    margin: $invoice-spacing;
  }
  .invoice-number-date {
    .title {
      width: 115px;
    }
  }
  .invoice-total-wrapper {
    width: 100%;
    max-width: 12rem;
    .invoice-total-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .invoice-total-title {
        margin-bottom: 0.35rem;
      }
      .invoice-total-amount {
        margin-bottom: 0.35rem;
        font-weight: 600;
      }
    }
  }

  @media (min-width: 768px) {
    .invoice-title {
      text-align: right;
      margin-bottom: 3rem;
    }
  }
}

// Invoice Edit & Add
.invoice-edit,
.invoice-add {
  .invoice-preview-card {
    .invoice-title {
      text-align: left;
      margin-right: 3.5rem;
      margin-bottom: 0;
    }
    .invoice-edit-input,
    .invoice-edit-input-group {
      max-width: 11.21rem;
    }
    .invoice-product-details {
      background-color: $product-details-bg;
      padding: 3.75rem 3.45rem 2.3rem 3.45rem;
      .product-details-border {
        border: 1px solid $border-color;
        border-radius: $border-radius;
      }
    }
    .invoice-to-title {
      margin-bottom: 1.9rem;
    }
    .col-title {
      position: absolute;
      top: -3.2rem;
    }
    .item-options-menu {
      min-width: 20rem;
    }
    .repeater-wrapper {
      &:not(:last-child) {
        margin-bottom: 3rem;
      }
    }
    .invoice-calculations {
      .total-amt-title {
        width: 100px;
      }
    }
  }
  @media (max-width: 769px) {
    .invoice-preview-card {
      .invoice-title {
        margin-right: 0;
        width: 115px;
      }
      .invoice-edit-input {
        max-width: 100%;
      }
    }
  }
  @media (max-width: 992px) {
    .col-title {
      top: -1.5rem !important;
    }
  }
  @media print {
    hr {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
  }
}
