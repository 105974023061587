@import "./assets/scss/direction-rtl.scss";

@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import 'themes/generated/theme.base.css';
@import 'themes/generated/theme.additional.css';
/* You can add global styles to this file, and also import other style files */
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import 'assets/css/dx.material.blue.light.compact.css';



@import "app/pages/menu/menu.component.scss";

$devexpress-popop-body-color: $body-bg;
$devexpress-popop-body-bg: $body-bg;

@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    src: url(assets/fonts/avenir/AvenirLTStdMediumTR.otf) format('truetype');
}

@font-face {
    font-family: 'Futura';
    src: url('assets/fonts/futura/futura medium bt.ttf') format('woff2');
}

.river-app-content {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.app-content {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.content-router{

}

router-outlet + * {
    /* your css */
    flex:1;
}



::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.disabled {
    cursor: not-allowed !important;
    pointer-events: none;
    opacity: 0.8;
}

.dx-popup-content .dx-dialog-content {
    padding: 20px !important;
}

.dx-popup-normal .dx-popup-content {
    padding: 5px !important;
}

.dx-dialog-message{
    padding: 20px;
}



.dx-fileuploader-wrapper {
    padding: 4px 0px 0px 0px !important;
}

.dx-popup-title {
    background-color: #282828 !important;
    color: white;
}

.dx-dialog .dx-popup-title {
    background-color: $body-bg !important;
    color: #101010;
}

.dx-popup-toolbar {
    height:40px;
    background-color: $body-bg;
}

.dx-dashboard-theme {
    background-color: $body-bg !important; 
}

.dx-toolbar-before {
    color: $body-color;
}

.modal-header-button {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;

    svg {
        margin-right: 0px !important;
    }
}

.fieldarea {
    margin: 8px 0px;
    width: 100%;
}

.fieldarea .label {
    font-weight: bold;
    color: #5f5f5f;
}

.fieldarea.top .field {
    margin: 4px 0px;
}

.fieldarea.left .label {
    width: 20%;
    font-weight: bold;
    float: left;
    white-space: pre-wrap !important;
}

.fieldarea.left .field {
    width: 80%;
    float: left;
}

.dx-template:hover .actions {
    display: block
}


render-component:hover .action-button-group {
    display: block
}

.actions {
    z-index: 3;
    height: 36px;
    width: 0px;
    overflow: hidden;
    margin-right: 4px;
    z-index: 3;
    background-color: white;
    border-radius: 4px;
    transition: width 0.3s ease-in-out;
}

.field-item:hover .actions {
    width: 75px;
}






.dx-texteditor.dx-editor-filled {
    background-color: rgb(225 220 220 / 22%) !important;
}

.dx-toolbar-text-auto-hide .dx-button .dx-button-text {
    display: none !important;
}

.dx-texteditor.dx-editor-filled.dx-state-hover {
    background-color: rgb(225 220 220 / 34%) !important
}

.dx-texteditor.dx-editor-filled {
    border: none !important;
    height: 30px !important;
}


/*Ekin*/
.formarea .dx-list-item,
.dx-list {
    border-top: none;
}

.fileuploader-container {
    border: 1px solid #d3d3d3;
    margin: 20px 20px 0 20px;
}

.field-item {
    margin: 20px 0px;
    position: relative
}

.field-item:hover .actions {
    display: block;
}

.field-item.edit-mode {
    cursor: pointer;
}

.field-item.edit-mode input {
    pointer-events: none;
}

.dx-overlay-shader {
    background-color: rgba(34, 41, 47, 0.5);
}

.popup-toolbar {
    background-color: $devexpress-popop-body-bg;
    border: none !important;
    padding: 4px;
}

.dx-popup-title {
    color: white !important;
}

.dx-popup-title .dx-icon-close {
    color: white !important;
}

.ps__rail-y {
    background-color: transparent !important
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover,
.dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover .dx-scrollable-scroll {
    width: 8px;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable {
    background-color: transparent !important;
}

.ps__thumb-y {
    max-width: 7px;
}

.horizontal-menu .header-navbar.navbar-horizontal ul#main-menu-navigation > li {
    margin-right: 0.857rem;
}

.modal-body {
    max-height: 90vh;
    overflow-y: auto;
}

.modal {
    z-index: 1056;
}

.modal-backdrop {
    z-index: 1049 !important;
}

/* Modal üzerindeki kapama buttonunun effect özellikleri kaldırıldı.*/
.modal .modal-header .close {
    box-shadow: none !important;
    background: transparent !important;
    transition: none !important;
    position: unset !important;
    transform: none !important;
    cursor: pointer !important;
}

/* Modal üzerindeki Header alanının yüksekliğini azaltmak ve form ismini dikey olarak ortalamak için yazıldı.*/
.modal-header {
    padding: 0.25rem 1.4rem !important;
    display: flex !important;
    align-items: center !important;
}

.footer-fixed .content.app-content {
    padding-bottom: 1rem !important;
}

.header-navbar .floating-nav {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
    margin-left: auto;
    margin-right: auto;
}

.navigation-header {
    margin: 1rem 0 1rem 2.2rem !important;
}

.ps__thumb-y {
    max-width: 7px;
}


/**************************************************************/
/**************************************************************/
/**************************************************************/
/**************************************************************/
/**************************************************************/

.frontpanel-wrapper.wrapper-full-page {
    min-height: 100vh;
    height: auto;
}

.frontpanel-wrapper {
    position: relative;
    top: 0;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1000000000;
    width: 100%;
    height: 100%;
}

.full-page.section-image {
    position: initial;
}

.section-image {
    background-size: cover;
    background-position: center center;
    position: relative;
    width: 100%;
}

.full-page .full-page-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
}

.login-page .link {
    font-size: 10px;
    color: #fff;
    text-decoration: none;
}

.full-page .main-content {
    padding-bottom: 0px;
    padding-top: 0px;
}

.login-page .dx-checkbox-icon {
    width: 18px;
    height: 18px;
    background-color: transparent;
    border: 1px solid #e3e3e3;
}

.login-page .dx-checkbox-text {
    width: 110px;
}

.dx-overlay-content.dx-loadpanel-content {
    /*top: 270px !important;*/
    height: 115px !important;
    font-weight: bold !important;
}

.login-page .form-check-label .form-check-sign {
    color: white;
    opacity: .9;
}

.login-page .dx-checkbox.dx-state-hover .dx-checkbox-icon {
    border: 1px solid #f96332;
}

.login-page .dx-checkbox-checked .dx-checkbox-icon {
    font: 16px/16px DXIcons;
    color: #f96332;
    text-align: center;
}

.login-page .dx-checkbox.dx-state-focused .dx-checkbox-icon {
    border: 1px solid #f96332;
}

.login-page .card-login .logo-container {
    width: 217px !important;
    margin-bottom: 10px !important;
}

.flagrepeater {
    background: rgba(255, 255, 255, 0.1);
    padding: 2px;
    padding-top: 17px;
    border-radius: 9px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: absolute;
    height: 144px;
    overflow: auto;
    width: 35px;
    border-top: 1px solid rgba(255, 255, 255, 0.32);
    padding-left: 5px;
}

.flagrepeaterbutton {
    padding: 0 0;
    width: 23px;
    height: 23px;
    background-color: transparent;
}

.flagrepeaterhide {
    display: none;
}

.flagselectedborder {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.flagselected {
    background: rgba(255, 255, 255, 0.1);
    padding: 5px;
    padding-top: 5px;
    border-radius: 9px;
    height: 35px;
    width: 35px;
}

.flagselectedbutton {
    padding: 0 0;
    width: 25px;
    height: 25px;
    background-color: transparent;
}

.flagrepeater::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.flagrepeater::-webkit-scrollbar-track {
    border-radius: 0;
}

/* Handle */
.flagrepeater::-webkit-scrollbar-thumb {
    background: #888;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 0;
}

/* Handle on hover */
.flagrepeater::-webkit-scrollbar-thumb:hover {
    background: #555;
}



.lds-dual-ring {
    display: inline-block;
    width: 50px;
    height: 50px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 45px;
    height: 45px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid silver;
    border-color: silver transparent silver transparent;
    animation: lds-dual-ring 1.8s linear infinite;
}

.input-group-text {
    flex-shrink: 0;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.front-panel-color-red {
    color: #fa5858;
}

.front-panel-color-green {
    color: #79b84a;
}

.dx-button-mode-contained.dx-button-default {
    margin-left: 0.5rem !important;
}

.text-white{
    color: $body-color !important;
}

.row{
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.form-group {
    margin-bottom: 0.5rem !important;
}
.col-form-label {
    padding-left: 0rem !important;
}