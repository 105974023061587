@import '@core/scss/base/pages/app-invoice.scss';
@import '@core/scss/angular/libs/datatables.component.scss';
@import '@core/scss/angular/libs/select.component.scss';

@import '@core/scss/base/bootstrap-extended/_include'; // Components includes
@import '@core/scss/base/bootstrap-extended/include'; // Components includes
@import '@core/scss/base/components/include'; // Components includes

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/assets/icons.css';

@import '@core/scss/base/colors';

// variable declaration
$datatables-bg-header-color: $table-head-bg;
$datatables-text-color: $gray-400;
$datatables-icon-color: #b0afb9;
$datatables-border-color: $border-color;
$datatables-footer-text-color: $text-muted;
$datatables-dark-text-color: $theme-dark-body-color;
$datatables-dark-footer-bg-color: $theme-dark-card-bg;
$datatables-dark-border-color: $theme-dark-border-color;
$datatables-dark-header-bg-color: $theme-dark-table-header-bg;
$datatables-dark-body-bg-color: $theme-dark-table-bg;
$datatables-dark-active-bg-color: $theme-dark-card-bg;


.log-html-warning {
    word-break: normal;
    border: 1px solid #ffbf00;
    border-left: 5px solid #ffbf00;
    display: block;
    padding: 10.5px;
    margin: 0 0 11px;
    background-color: #ffe084;
    height: 43px;
    overflow-y: hidden;
    overflow-x: auto;
    max-height: 43px;
    color: #6d5203;
    font-size: 14px;
    user-select: none;
}

.full-h{
    height:100%;
}

.customButton {
    background-color: $datatables-bg-header-color;
    color: $datatables-text-color;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}


.customButton:hover {
    background-color: #f6f6f6;
    color: $datatables-text-color;
}


/* DataGrid Css */
.dx-datagrid {
    .dx-column-lines > td {
        border: unset !important;
    }

    .dx-datagrid-rowsview {
        border: unset !important;
    }

    dx-widget dx-datagrid-pager dx-pager {
        border-top: 1px solid $datatables-border-color;
        padding: 8px 4px;
    }

    .customColor {

        > td {
            color: unset !important;
            background-color: unset !important;
        }

        
    }

    
    .dx-row {
        border-top: 1px solid;
        border-color: $datatables-border-color;
        
        > td {
            color: $datatables-text-color;
            height: 40px;
            font-family: 'Montserrat';
            font-size: 13px;

            .dx-template-wrapper {
                height: 100%;
            }
        }
    }
    /*tr, .dx-row, .dx-column-lines, .dx-datagrid-filter-row {
        border-bottom: 1px solid;
        border-color: $datatables-border-color;
    }*/

    .dx-row, .dx-column-lines .dx-header-row {
        td {
            padding: 12px 7px;
        }
    }


    .dx-row .dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit:not(.dx-focused) .dx-link, .dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused), .dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
        background-color: #f6f6f6;
        color: $datatables-text-color;
    }

    .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
        background-color: $datatables-bg-header-color;
        color: $datatables-text-color;
        border-bottom: unset !important;
        font-weight: bold;
        font-size: 0.8rem;
        font-family: "Montserrat";
        text-transform: uppercase;
        letter-spacing: 1px;

        .dx-icon {
            color: $datatables-icon-color;
        }

        .dx-dropdowneditor-icon:before {
            color: $datatables-icon-color;
        }

        .dx-state-hover {
            background-color: #f6f6f6;
        }

        .dx-submenu > ul > li > .dx-menu-item {
            background-color: $datatables-bg-header-color !important;

            .dx-state-hover {
                background-color: $datatables-bg-header-color !important;
            }
        }

        .dx-header-filter {
        }

        .dx-texteditor.dx-state-focused.dx-editor-outlined {
            border-bottom: unset !important;
        }

        .dx-texteditor-input {
            background-color: $datatables-bg-header-color;
            color: $datatables-text-color;
        }

        .dx-widget {
            background-color: $datatables-bg-header-color;
        }
    }

    .dx-texteditor.dx-state-focused.dx-editor-outlined {
        border-color: transparent !important;
    }

    .dx-texteditor-input {
        background-color: $datatables-bg-header-color;
        color: $datatables-text-color;
    }

    .dx-datagrid-search-panel {
        margin: unset !important;
        background-color: transparent;
        border-color: transparent;
        input {
            font-family: 'Montserrat';
            font-size: 13px;
        }

        .dx-texteditor-input {
            height: 35px;
        }

        .dx-placeholder {
            top: 0px;
            color: $datatables-text-color;
        }

        .dx-texteditor-buttons-container {
            background-color: $datatables-bg-header-color !important;
        }
    }

    .dx-toolbar-items-container {
        height: 40px;
        font-family: 'Montserrat';
        font-size: 13px;
        font-weight: bold;
        background-color: white !important;
    }

    .header {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .dx-datagrid-column-chooser .dx-overlay-content .dx-popup-content .dx-column-chooser-item {
        margin-bottom: 8px;
        background-color: #fff;
        color: #959595;
        font-weight: 400;
        border: 1px solid #ddd;
        padding: 7px;
        border-radius: 5px !important;
        box-shadow: 0 2px 4px -2px rgba(0,0,0,.2) !important;
    }

    .dx-pager .dx-page-sizes .dx-selection, .dx-pager .dx-pages .dx-selection {
        background-color: $datatables-bg-header-color;
        color: $datatables-text-color;
    }

    .dx-datagrid-filter-panel {
        .dx-checkbox-checked .dx-checkbox-icon {
            color: $datatables-icon-color;
        }

        .dx-icon-filter {
            color: $datatables-icon-color;
        }

        .dx-datagrid-filter-panel-clear-filter {
            color: $datatables-icon-color;
        }
    }
} 
