// Import first main menu mixin
@import '../../@core/scss/base/core/mixins/main-menu-mixin.scss';

.direction-rtl {
    direction: rtl !important;

    // Perfect scrollbar RTL fix
    .ps__rail-y {
        right: auto !important;
        left: 1px !important;
    }

    .dropdown-item {
        display: flex;

        svg {
            margin-left: 0.5rem !important;
        }
    }

    .navigation-header {
        display: flex;
        margin-right: 1.75rem !important;
    }

    .navbar-container .search-input .search-list.show {
        padding-right: 0px;
    }

    .gridster-item-container {
        direction: rtl
    }

    .app-content {
        margin-left: 0px !important;
        margin-right: 80px;
    }

    .footer {
        display: flex;
    }

    .vertical-layout.vertical-menu-modern.menu-collapsed .footer {
        margin-left: unset !important;
        margin-right: 80px;
    }

    .vertical-layout.vertical-menu-modern.menu-expanded .footer {
        margin-left: unset !important;
        margin-right: 260px;
    }

    .menu-expanded {
        .app-content {
            margin-right: 265px !important;
        }
    }

    .chat-application {

        .chat-river-content {
            right: unset !important;
            left: 10px;
        }

        .chat-river-footer {
            right: unset !important;
            left: 10px;
        }
    }
    // ================================================================================================
    //  File Name: custom-rtl.scss
    //  Description: RTL support SCSS file.
    //  ----------------------------------------------------------------------------------------------
    //  Item Name: Vuexy - Vuejs, React, Angular, HTML & Laravel Admin Dashboard Template
    //  Author: PIXINVENT
    //  Author URL: http://www.themeforest.net/user/pixinvent
    // ================================================================================================
    // Variables
    // ------------------------------
    // Align icons position
    .main-menu {
        .navigation li > a > svg,
        .navigation li > a > i,
        .dropdown-menu svg,
        .dropdown-menu i,
        .dropdown-user > a > svg,
        .dropdown-user > a > i,
        .navigation > li > a > svg,
        .navigation > li > a > i {
            float: right;
        }

        .navigation > li ul li > a {
            display: flex;
            align-items: center;
        }
    }
    // Transformed menu icons
    .vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub > a:after,
    .vertical-layout.vertical-overlay-menu.menu-open .main-menu .navigation li.has-sub > a:after {
        transform: rotate(180deg);
    }

    .vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub.open:not(.menu-item-closing) > a:after {
        transform: rotate(90deg);
    }
    // Dropdown RTL Changes
    .header-navbar .navbar-container ul.nav li.dropdown {
        .dropdown-menu {
            top: 41px !important;

            &::before {
                top: 1px;
            }
        }
    }

    .header-navbar {
        .dropdown,
        .dropup {
            .dropdown-menu.dropdown-menu-right::before {
                right: auto;
                left: 0.5rem;
            }
        }
    }

    .dropdown,
    .dropup,
    .btn-group {
        .dropdown-menu {
            right: auto !important;
            top: 0 !important;
            left: auto !important;

            &.dropdown-menu-right {
                left: 0 !important;

                &::before {
                    right: 0.6rem;
                    left: auto;
                }
            }
        }
    }

    .dropleft {


        .dropdown-menu {
            left: 0 !important;
            margin-left: 0;
            margin-right: 0.5rem;
        }
    }

    .dropright {


        .dropdown-menu {
            left: 0 !important;
            margin-right: 0;
            margin-left: 0.5rem;
        }
    }
    // Input Group dropdown
    .input-group {
        .dropdown-menu.show {
            top: 0 !important;
            right: auto !important;
            left: 0px !important;
        }
    }
    // BS Toast
    .toast {
        right: 1rem;
    }
    // Select2
    .select2-container--default .select2-selection--single .select2-selection__arrow {
        left: 1px;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice {
        float: right;
    }

    .select2-search__field {
        direction: rtl;
    }


    .apexcharts-canvas .apexcharts-text.apexcharts-yaxis-label {
        transform: translate(14px, 0);
    }
    // Chartist
    .chartjs-render-monitor {
        margin-right: 1rem;
    }
    // Datatable
    div.dataTables_wrapper div.dataTables_filter {
        text-align: left !important;
    }

    table.dataTable thead .sorting:before,
    table.dataTable thead .sorting_asc:before,
    table.dataTable thead .sorting_desc:before {
        right: 0.45rem;
    }
    // Avatar group
    .avatar-group {
        // Avatar Group Sizings
        .avatar {
            margin-right: -0.785rem;
            margin-left: 0;
        }

        .avatar-sm {
            margin-right: -0.65rem;
        }

        .avatar-lg {
            margin-right: -1.5rem;
        }

        .avatar-xl {
            margin-right: -1.85rem;
        }
    }
    // Breadcrumb
    .breadcrumb:not([class*='breadcrumb-']),
    .breadcrumb.breadcrumb-chevron {
        .breadcrumb-item + .breadcrumb-item {
            &:before {
                transform: rotate(180deg);
            }
        }
    }
    // Pagination
    .pagination .page-item {
        &.prev-item,
        &.prev,
        &.previous {
            .page-link {
                &:before {
                    transform: rotate(180deg);
                }

                &:hover,
                &:active {
                    &:before {
                        transform: rotate(180deg);
                    }
                }
            }

            &.disabled {
                .page-link {
                    &:before {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        &.next-item,
        &.next {
            .page-link {
                &:after {
                    transform: rotate(180deg);
                }

                &:hover,
                &:active {
                    &:after {
                        transform: rotate(180deg);
                    }
                }
            }

            &.disabled {
                .page-link {
                    &:before {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    code[class*='language-'],
    pre[class*='language-'] {
        direction: ltr;
    }

    @media print {
        code[class*='language-'],
        pre[class*='language-'] {
            text-shadow: none;
        }
    }
    // Calendar
    .fc .fc-header-toolbar .fc-right .fc-button.fc-prev-button .fc-icon {
        right: 4px !important;
    }

    .fc .fc-header-toolbar .fc-right .fc-button.fc-next-button .fc-icon {
        left: -3px !important;
    }
    // carousel changes
    .carousel-control-next {
        left: auto;
        right: 0;
    }

    .carousel-control-prev {
        left: 0;
        right: auto;
    }
    // Tooltip
    .bs-tooltip-left .arrow::before,
    .bs-tooltip-auto[x-placement^='left'] .arrow::before {
        border-width: 0.4rem 0 0.4rem 0.4rem;
    }

    .bs-tooltip-left .arrow,
    .bs-tooltip-auto[x-placement^='left'] .arrow {
        right: 0;
        left: auto;
    }

    .bs-tooltip-right .arrow::before,
    .bs-tooltip-auto[x-placement^='right'] .arrow::before {
        border-width: 0.4rem 0.4rem 0.4rem 0;
    }

    .bs-tooltip-right .arrow,
    .bs-tooltip-auto[x-placement^='right'] .arrow {
        left: 0;
        right: auto;
    }
    // Popover Style
    .popover {
        right: auto !important;
    }
    // Progress
    .progress-bar-animated {
        animation: progress-bar-stripes 40s linear infinite;
    }
    // FAQ and Pricing page
    .faq-navigation img,
    .pricing-free-trial .pricing-trial-img {
        transform: scaleX(-1);
    }

    .feather-chevron-left,
    .feather-chevron-right {
        transform: rotate(-180deg) !important;
    }
    // Kanban
    .kanban-application {
        .kanban-container {
            .kanban-item {
                i,
                svg {
                    margin-right: 0 !important;
                    margin-left: 0.25rem;
                }
            }
        }
    }
    // Invoice List
    .invoice-list-wrapper {
        .dataTables_filter {
            input {
                margin-left: 0 !important;
                margin-right: 0.5rem;
            }
        }

        .dropdown .dropdown-menu.dropdown-menu-right {
            left: 2rem !important;
        }
    }
    // File Manager
    .file-manager-application {
        .sidebar-file-manager {
            .sidebar-inner {
                .my-drive .jstree-node.jstree-closed > .jstree-icon {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .chat-sidebar {
        .input-group {
            direction: ltr !important;
        }

        #chat-search {
            direction: rtl !important;
        }

        .chat-users-list {
            padding-right: 0 !important;
        }
    }

    .chat-app-form {
        .input-group {
            direction: ltr !important;
        }

        .form-control {
            direction: rtl !important;
        }
    }
    /*.content.app-content {
        left: 0 !important;
        right: 260px !important;
    }*/

    .header-navbar.floating-nav {
        position: fixed;
        top: 0 !important;
        left: 0 !important;
        right: 260px !important;
    }

    .dx-datagrid-header-panel {
        .dx-searchbox .dx-icon-search {
            left: 0 !important;
        }
    }

    .chat-river-content {

        .sidebar-left {
            float: right;
        }
    }

    #kanban {
        .card-avatar {
            left: 21px !important;
            bottom: 14px !important;
            right: unset !important;
        }

        .subtask-card {
            right: 19% !important;
        }

        .subtask-subject {
            padding-left: 40px !important;
        }

        .form-label {
            a {
                float: left !important;
            }
        }
    }

    .sideBarClass {
        float: left !important;
        right: unset !important;
        left: 0 !important;

        .btn-cancel {
            margin-right: 10px !important;
        }

        .nav {
            padding-right: 0 !important;
            padding-left: unset !important;
        }
    }

    .dx-scheduler-container {
        .dx-toolbar-item:last-child {
            padding-left: 72px !important;
        }
    }

    .dateTodo {
        margin-left: 20px;
    }

    .dropdown-menu {
        .media-list .media .media-left {
            margin-left: 1rem;
            margin-right: unset;
        }
    }

    .stick-notes {
        direction: ltr !important;
    }

    .report-designer {
        .dx-toolbar-before {
            visibility: hidden !important;
        }
    }

    #profile-info {
        .card-text {
            text-align: right !important;
        }

        .langMarginStyle {
            margin-right: -10px !important;
        }

        .ml-auto {
            margin-left: unset !important;
            margin-right: auto !important;
        }
    }

    #user-profile {
        .modal {
            .nav-tabs {
                width: 218px !important;
            }

            .nav {
                padding-right: 0 !important;
                padding-left: unset !important;
            }
        }

        .card-body {
            .mr-1 {
                margin-right: unset !important;
                margin-left: 1rem !important;
            }

            .ml-1 {
                margin-left: unset !important;
                margin-right: 1rem !important;
            }

            .input-group {
                direction: ltr !important;
            }

            .form-control {
                direction: rtl !important;
            }

            .custom-control {
                padding-right: 1.8rem !important;
                padding-left: 1.8rem !important;
            }
        }
    }

    .nav-item {
        .avatar {
            margin-right: 10px !important;
        }
    }
}
